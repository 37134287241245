div.rdrDefinedRangesWrapper {
  width: 160px;
  padding: 12px 16px;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: currentColor;
  font-weight: 500;
}

button.rdrStaticRange {
  border-bottom: 0px;
  border-radius: 8px;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  border-radius: 8px;
}

button.rdrStaticRangeSelected {
  background: #deebff;
}

.rdrInputRanges {
  display: none;
}

.rdrInputRangeInput:focus,
.rdrInputRangeInput:hover {
  border-color: white;
  outline: 0;
  color: rgb(37, 176, 218);
  background-color: red;
}

.rdrDay {
  height: 4em;
}

.rdrStartEdge ~ .rdrDayNumber,
.rdrEndEdge ~ .rdrDayNumber {
  background: #0052cc;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
}

/* .rdrEndEdge , .rdrStartEdge {
    border-radius: 2em;
} */

.rdrStartEdge,
.rdrEndEdge,
.rdrInRange {
  /* color: red; */
  background-color: #deebff;
}

.rdrInRange ~ .rdrDayNumber span {
  color: #0052cc !important;
  font-weight: 500;
  font-size: 14px;
}

button.rdrDayHovered:hover {
  background: #deebff !important;
  border-radius: 12px;
  /* border: 1px solid red!important; */
}

@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
div.rdrDefinedRangesWrapper {
  width: 160px;
  padding: 12px 16px;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: currentColor;
  font-weight: 500;
}

button.rdrStaticRange {
  border-bottom: 0px;
  border-radius: 8px;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  border-radius: 8px;
}

button.rdrStaticRangeSelected {
  background: #deebff;
}

.rdrInputRanges {
  display: none;
}

.rdrInputRangeInput:focus,
.rdrInputRangeInput:hover {
  border-color: white;
  outline: 0;
  color: rgb(37, 176, 218);
  background-color: red;
}

.rdrDay {
  height: 4em;
}

.rdrStartEdge ~ .rdrDayNumber,
.rdrEndEdge ~ .rdrDayNumber {
  background: #0052cc;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
}

/* .rdrEndEdge , .rdrStartEdge {
    border-radius: 2em;
} */

.rdrStartEdge,
.rdrEndEdge,
.rdrInRange {
  /* color: red; */
  background-color: #deebff;
}

.rdrInRange ~ .rdrDayNumber span {
  color: #0052cc !important;
  font-weight: 500;
  font-size: 14px;
}

button.rdrDayHovered:hover {
  background: #deebff !important;
  border-radius: 12px;
  /* border: 1px solid red!important; */
}

/* SFProText font */
@font-face {
  font-family: SFProText;
  src: url(/static/media/SF-Pro-Text-Regular.dde64654.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: SFProText;
  src: url(/static/media/SF-Pro-Text-Bold.bec47ec8.woff2) format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: SFProText;
  src: url(/static/media/SF-Pro-Text-Light.abb3719a.woff2) format('woff2');
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
  unicode-range: U+00-7F;
}

/* Charlie_display font */
@font-face {
  font-family: charlieDisplay;
  src: url(/static/media/CharlieDisplay-Regular.6e3c973c.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: charlieDisplay;
  src: url(/static/media/CharlieDisplay-Bold.9ce68c56.woff2) format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: charlieDisplay;
  src: url(/static/media/CharlieDisplay-Light.44e926e6.woff2) format('woff2');
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
  unicode-range: U+00-7F;
}

@font-face {
  font-family: SFProDisplay;
  src: url(/static/media/SFProDisplay-Regular.279c7970.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: normal;
  unicode-range: U+00-7F;
}

/* Mariupol_Reqguler font */
@font-face {
  font-family: MariupolReguler;
  src: url(/static/media/Mariupol_Regular.30485e26.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+00-7F;
}

.App {
  text-align: center;
  font-family: 'Inter';
  min-height: 100vh;
}

/* .MuiListItemButton-root:hover .icon>path {
  fill: #0052CC;
} */

.MuiFormLabel-root.MuiInputLabel-root {
  font-family: 'Inter';
}

.MuiButtonBase-root.MuiButton-root {
  font-family: 'Inter';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

